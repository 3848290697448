import { t } from 'i18next';
import React from 'react';
import PlayNowButton from './common/PlayNowButton';
import { Image } from '@nextui-org/react';

export default function UnlimitedTours() {
  return (
    <section className="pb-12 lg:pb-0">
      {/* <h3 className="text-center text-7xl tracking-widest uppercase">
        <span className="text-white">Un</span>
        <span className="text-black">limited</span>
      </h3> */}
      <div className="container mx-auto w-screen px-0 overflow-x-hidden lg:gap-8 flex items-center justify-between flex-col lg:flex-row">
        <div className="w-full lg:max-w-[45%]">
          <Image
            src="/images/home/home-tours.webp"
            alt="home tournaments"
            className="object-cover w-full"
          />
        </div>
        <div className="text-center lg:max-w-[50%] flex-center flex-col gap-10">
          <div className="flex-center flex-col gap-5 lg:gap-10">
            <h3 className="text-center font-display text-xl lg:text-4xl capitalize ">
              {t('paly_unlimited_tournaments')}
            </h3>
            <p className="text-g dark:text-jacarta-200 text-center text-medium lg:text-lg">
              {t('home_unlimited_tournaments_p')}
            </p>
          </div>
          <PlayNowButton />
        </div>
      </div>
    </section>
  );
}
