import React from 'react';
import PlayNowButton from './common/PlayNowButton';
import { Image } from '@nextui-org/react';
import { t } from 'i18next';

export default function ResultTracking() {
  return (
    <section className="p-12 lg:p-12">
      {/* <h3 className="text-center text-7xl tracking-widest uppercase">
        <span className="text-white">Un</span>
        <span className="text-black">limited</span>
      </h3> */}
      <div className="container mx-auto gap-8 w-full flex items-center justify-between flex-col lg:flex-row">
        <div className="w-full lg:max-w-[50%]">
          <Image
            src="/images/home/home-results-tracking.webp"
            alt="home tournaments"
            className="object-cover w-full"
          />
        </div>
        <div className="text-center lg:max-w-[48%] flex-center flex-col gap-10">
          <div className="flex-center flex-col gap-5 lg:gap-10">
            <h3 className="text-center font-display text-xl lg:text-4xl capitalize">
              {t('home_results_tracking')}
            </h3>
            <p className="text-g dark:text-jacarta-200 text-center text-medium lg:text-lg">
              {t('home_results_tracking_p')}
            </p>
          </div>
          <PlayNowButton />
        </div>
      </div>
    </section>
  );
}
