import React from 'react';
import PlayNowButton from './common/PlayNowButton';
import { Image } from '@nextui-org/react';
import { t } from 'i18next';

export default function PaymentMethods() {
  return (
    <section className="p-12">
      <div className="container mx-auto gap-12 lg:gap-8 w-full flex items-center justify-between flex-col lg:flex-row">
        {/* payment methods part */}
        <div className="w-full lg:max-w-[45%] grid grid-cols-3 gap-3">
          {paymentMethods.map((method) => (
            <Image
              key={method.id}
              src={method.image}
              alt={method.alt}
              className="object-cover w-full"
            />
          ))}
        </div>
        <div className="text-center lg:max-w-[50%] flex-center flex-col gap-10">
          <div className="flex-center flex-col gap-5 lg:gap-10">
            <h3 className="text-center font-display text-xl lg:text-4xl capitalize">
              {t('home_payment_methods')}
            </h3>
            <p className="text-g dark:text-jacarta-200 text-center text-medium lg:text-lg">
              {t('home_payment_methods_p')}
            </p>
          </div>
          <PlayNowButton />
        </div>
      </div>
    </section>
  );
}

const paymentMethods = [
  {
    id: 1,
    image: '/images/payment-methods/apple-pay.webp',
    alt: 'apple pay',
  },
  {
    id: 2,
    image: '/images/payment-methods/google-pay.webp',
    alt: 'Google pay',
  },
  {
    id: 3,
    image: '/images/payment-methods/credit-card.webp',
    alt: 'credit card',
  },
  {
    id: 4,
    image: '/images/payment-methods/cliq.webp',
    alt: 'cliq',
  },
  {
    id: 5,
    image: '/images/payment-methods/zain-cash.webp',
    alt: 'zain cash',
  },
  {
    id: 6,
    image: '/images/payment-methods/bank-transfer.webp',
    alt: 'bank transfer',
  },
  {
    id: 7,
    image: '/images/payment-methods/paypal.webp',
    alt: 'paypal',
  },
  {
    id: 8,
    image: '/images/payment-methods/fawry.webp',
    alt: 'fawry',
  },
  {
    id: 9,
    image: '/images/payment-methods/vodaphone-cash.webp',
    alt: 'vodaphone cash',
  },
];
